
interface IYoutubeVideo {
  url: string
}

export const YoutubeVideo = ({ url }: IYoutubeVideo) => {

  return (
    <iframe
      style={{ aspectRatio: '16 / 9' }}
      width="100%"
      src={url}
      title="YouTube video player"
      frameBorder="0"
      allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    />
  )
}