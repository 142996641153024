import { Box, Stack, Typography } from "@mui/material"
import { YoutubeVideo } from "../components/common/YoutubeVideo"

export const Pregon = () => {

  return (
    <Stack gap='2rem'>
      <Stack alignItems='center'>
        <Typography variant='h4'>PREGÓN</Typography>
      </Stack>
      <Stack sx={{
        flexDirection: {
          xs: 'column-reverse',
          lg: 'row'
        },
        justifyContent: {
          xs: 'center',
          lg: 'space-around'
        },
        alignItems: {
          xs: 'center',
          lg: 'start'
        },
        gap: {
          xs: '2rem',
          lg: '1rem'
        }
      }}>
        <Box sx={{
          maxWidth: {
            xs: 'none',
            lg: '50vw'
          }
        }}>
          <Typography variant='body1'>
            Pregón de las Glorias del Rocío 2024 de la Hdad. de Ntra. Sra. del Rocío de Sabadell, Filial 123 de Almonte. Este año ha recaído esta ardua misión a un pregonero que no es nuevo para esta Hermandad, esta es la cuarta vez que pregona ante la que es su Hermandad. Desde recién nacido ya ha pertenecido a la misma, con tres meses de vida fue presentado a la Virgen del Rocío.
          </Typography>
          <Typography variant='body1'>
            El flamenco recorre sus venas y la poesía su camino, de este catalán con raíces andaluzas. Fue Hermano Mayor y Presidente de esta Hermandad y ha trabajado con el Parlament de Catalunya y el Parlamento Europeo de Bruselas. Se ha subido al atril hasta en más de sesenta ocasiones. Ha pregonado a Sevilla, en Huelva, su voz ha llegado hasta Bruselas, su maestría y su poesía ha llegado a muchos rincones y esa es la muestra de que este pregonero levanta emociones.
          </Typography>
          <Typography variant='body1'>
            Toni Valenzuela Plaza este año le vuelve a pregonar a Sabadell con motivo de la incorporación de la carreta propia de la Hermandad que se integrará al patrimonio de la misma, Dios mediante, este mismo año y que sus peregrinos desandarán los caminos de Doñana en esta próxima Romería de Pentecostés.
          </Typography>
        </Box>
        <YoutubeVideo url='https://www.youtube.com/embed/ZFmEDF9Aqhw?start=554' />
      </Stack>
    </Stack>
  )
}