import { Stack, Typography } from "@mui/material"
import Rocio from '../static/rocio.webp'
import Audio from '../static/AudioRocio.mp3'
import { useIsMobile } from "../hooks/isMobile"

const horizontalFade = 'linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 95%)'
const verticalFade = 'linear-gradient(rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 95%)'

export const HMText = () => {

  const isMobile = useIsMobile()

  const text = () => (
    <>
      <Typography variant='body1'>
        Hoy solo te va a hablar una de tus hijas, nerviosa y con el corazón encogido por lo que le queda por vivir, por llevar a su Hermandad ante tus plantas: responsabilidad y honor que este año ha recaído sobre mí. Tantas veces que te he rezado, tanto que te he llorado, tantas veces que te he mirado, en la ermita cuántas horas he pasado.
      </ Typography>
      <Typography variant='body1'>
        Ya no soy aquella niña pequeña que soñaba todo el año con que llegara la romería, aquel sueño se ha cumplido y ahora voy con mi Hermandad de Sabadell siendo yo su guía. Hermana Mayor, responsabilidad y privilegio de guiarte peregrino, es la fe que va creciendo en nuestra Hermandad por verte Virgen Santa del Rocío.
      </ Typography>
      <Typography variant='body1'>
        Ya veo a lo lejos tu ermita, mis pies cansados, doloridos de desandar tu Camino de los Llanos junto a mis hermanos detrás de nuestro Simpecado, ese camino que tú andas cuando te visten de Pastora cada siete años.
      </ Typography>
      <Typography variant='body1'>
        Simpecado Divino, este año con más emoción que nunca, cuatro serán las carriolas y más de cien tus peregrinos, que andarán por las arenas, para llevarte hasta sus plantas, derrochando sudor y lágrimas hasta llegar al Rocío. Carreta trabajada con los sueños de la gente, sueños por verte a ti en tu carreta por el camino deslumbrante, Simpecado bendito eres tú quien nos hace fuertes, a los peregrinos que te acompañamos de Sabadell hasta Almonte. Agarraos a tu Carreta Blanca, bóveda celeste que te corona, ataviada ya de flores con los tres colores de tu medalla. No te asustes Simpecado, por el tintineo de las campanitas, campanitas llenas de promesas, de los hermanos que te acompañan, ya sea en el balcón del cielo o agarrados a tu carreta, ellos son tus peregrinos, ellos son muestra de su fe rociera. Tú no te pongas triste porque este año sí que bajan, desde el cielo hasta la tierra para caminar junto a ti Simpecado de mis entrañas.
      </ Typography>
      <Typography variant='body1'>
        Ya repican las campanas, ya se ilumina el cielo de colores para que salga la reina de Almonte, su pueblo y los buenos rocieros ya te esperan con las palmas en alto llamándote, algunos rezándote, otros llorándote y otros llamándote guapa a la Señora que es su guía y su norte.
      </ Typography>
      <Typography variant='body1'>
        Porque los colores de mi Hermandad son blanco, amarillo y verde, que los llevo en mi medalla colgada cerca de mi corazón para sentirte y verte. Por qué yo voy con Sabadell, voy con los míos, para cantarle y rezarle a mi Virgen del Rocío.
      </ Typography>
      <Stack sx={{
        flexDirection: {
          sx: 'column',
          sm: 'row'
        },
        alignItems: {
          sx: 'start',
          sm: 'end'
        }
      }} justifyContent='space-between'>
        <Stack>
          <Typography variant='body1'>
            ¡Viva la Virgen del Rocío!
          </Typography>
          <Typography variant='body1'>
            ¡Viva la Hermandad de Sabadell!
          </Typography>
        </Stack>
        <Typography variant='body1'>
          Hermana Mayor Ana Berbel García
        </Typography>
      </Stack>
    </>
  )

  if (isMobile) {
    return (
      <Stack direction='column'>
        <div style={{
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
          height: '500px',
          maskImage: verticalFade,
          WebkitMaskImage: verticalFade
        }}>
          <img alt='Virgen del Rocío' src={Rocio} style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -40%)',
            maxWidth: 'none'
          }} />
        </div>
        <Stack direction='column' gap='2rem' maxWidth='100%'>
          <Stack direction='column' gap='1rem'>
            <audio controls src={Audio} />
            <Typography variant='h4'>ROCÍO 2024</Typography>
          </Stack>
          {text()}
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack direction='row'>
      <Stack direction='column' gap='2rem'
        sx={{
          maxWidth: {
            xs: '100%',
            md: '50%'
          }
        }}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='h4'>ROCÍO 2024</Typography>
          <audio controls src={Audio} />
        </Stack>
        {text()}
      </Stack>
      <div style={{
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        maskImage: horizontalFade,
        WebkitMaskImage: horizontalFade
      }}>
        <img alt='Virgen del Rocío' src={Rocio} style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 'none'
        }} />
      </div>
    </Stack>
  )
}