import { Container, Stack } from "@mui/material"
import { HMText } from "./HMText"
import { Cartel } from "./Cartel"
import { Pregon } from "./Pregon"

export const Home = () => {

  return (
    <Container>
      <Stack gap='4rem'>
        <HMText />
        <Cartel />
        <Pregon />
      </Stack>
    </Container>
  )
}