import { Box, Stack, Typography } from "@mui/material"
import CartelImg from '../static/Cartel.jpg'

export const Cartel = () => {

  return (
    <Stack gap='2rem'>
      <Stack alignItems='center'>
        <Typography variant='h4'>CARTEL 2024</Typography>
      </Stack>
      <Stack sx={{
        flexDirection: {
          xs: 'column-reverse',
          lg: 'row'
        },
        justifyContent: {
          xs: 'center',
          lg: 'space-between'
        },
        alignItems: {
          xs: 'center',
          lg: 'start'
        },
        gap: {
          xs: '2rem',
          lg: '0'
        }
      }}>
        <Box sx={{
          maxWidth: {
            md: 'none',
            lg: '50vw'
          }
        }}>
          <Stack gap='1rem'>
            <Typography variant='body1'>
              Entonces tu luz despuntará como la aurora y tu recuperación brotará con rapidez.
            </Typography>
            <Typography variant='body1'>
              Óleo sobre tabla, de un metro de alto por 70 cm de ancho, presidido por la Santísima Virgen del Rocío y su Divino infante. Estampa posterior a su reciente restauración. Aparece orlada sobre la silueta de vuestra nueva carreta. Gran estreno que no podía faltar en la pintura por ser el Baluarte de vuestra constancia y premio a vuestra disposición; para orgullo de los Rocieros de Sabadell, así como trono para vuestro Bendito Simpecado desde Almonte hasta las Plantas de tan celestial Señora.
            </Typography>
            <Typography variant='body1'>
              Acompañado de elementos identificativos como son la bandera y torres típicas de vuestra ciudad, así como unas rosas de los colores de nuestra Patria Española por la tan maravillosa estrofa de vuestra Salve, y que tan acertadamente reconoce a la Virgen como "La flor más bella de España entera".
            </Typography>
            <Typography variant='body1'>
              ¿Qué fondo? Un bello amanecer de arrebol.
            </Typography>
            <Typography variant='body1'>
              Amanecéis como institución con vuestra nueva carreta. Amanecen vuestras almas cada vez que acudís a Ella y le rogáis atienda vuestras peticiones. Amanecen vuestros corazones de felicidad cada vez que su nombre roza vuestros labios. Amanecéis festivamente cada vez que le cantáis una Sevillana. Amanecéis fortalecidos cada vez que os reunís en vuestra sede social. Amanecéis y Brotáis cada vez que venís hasta vuestra Parroquia como rocieros a recibir a Jesús Sacramentado. Amanecéis, como nueva vida, precisamente en esa amanecida del lunes de Pentecostés. No cansaros. Tenéis un papel fundamental en el mensaje mundial de la Virgen del Rocío. Sois esenciales. No cansaros y sobre todo disfrutarlo.
            </Typography>
            <Stack alignItems='center'>
              <Typography variant='body1'>
                La Virgen le dice al Niño
              </Typography>
              <Typography variant='body1'>
                Atentos a estos que vienen
              </Typography>
              <Typography variant='body1'>
                Desde la otra punta de España.
              </Typography>
              <Typography variant='body1'>
                Vaya mérito que tienen
              </Typography>
              <Typography variant='body1'>
                No los detiene nada
              </Typography>
              <Typography variant='body1'>
                Cuanta fe los sostienen.
              </Typography>
              <Typography variant='body1'>
                A lo que graciosamente
              </Typography>
              <Typography variant='body1'>
                Le pide su Divino hijo
              </Typography>
              <Typography variant='body1'>
                Se justa con ellos Madre
              </Typography>
              <Typography variant='body1'>
                Regalales nuestro camino.
              </Typography>
            </Stack>
            <Stack alignItems='end'>
              <Typography variant='body1'>
                D. Joaquin Guerrero Morejón
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Stack alignItems='end'>
          <Box style={{ height: '70vh', overflow: 'hidden' }}>
            <img alt='Cartel de la Romería del Rocío 2024' src={CartelImg} style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }} />
          </Box>
          <Typography variant='body2'>Autor: D. Joaquin Guerrero Morejón</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}