import { MyRoutes } from "./routes"
import { NotificationProvider, PositionX, PositionY } from "@magicdidac/notifications"
import { useIsMobile } from "./hooks/isMobile"

export const App = () => {
  const isMobile = useIsMobile()

  return (
    <div style={{
      background: 'linear-gradient(90deg, rgb(255, 255, 255) 95%, rgb(1, 138, 44) 95% 97.5%, rgb(252, 222, 2) 97.5%)',
      minHeight: '100vh'
    }}>
      <NotificationProvider positionY={PositionY.top} positionX={isMobile ? PositionX.center : PositionX.right} width={isMobile ? '75vw' : '400px'}>
        <div style={{ marginRight: '5%', paddingTop: '2rem', paddingBottom: '2rem' }}>
          <MyRoutes />
        </div>
      </NotificationProvider>
    </div>
  )
}