import { createTheme } from "@mui/material"

export const primaryColor = '#000'
export const secondaryColor = '#e0e0e0'

export const webTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: primaryColor,
            contrastText: secondaryColor
        },
        secondary: {
            main: secondaryColor,
            contrastText: primaryColor
        }
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    '@media (min-width: 1200px)': {
                        maxWidth: 1600
                    },
                    marginRight: '0'
                }
            }
        },
    }
})
